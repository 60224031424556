
import { defineComponent, reactive, ref, UnwrapRef, watchEffect } from "vue";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { CREATE_OR_UPDATE_MEET, GET_MEET } from "@/apollo/gql.meet";
import { SelectProps } from "ant-design-vue";
import { capitalize } from "lodash";
import { Meet } from "../../graphql/generated";
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";

type FormState = {
  time: string;
  street: string;
  house: string;
  apartment: string;
  adjustment: boolean;
  hunter?: number;
  manager: string;
  type: string;
  source: string;
  result: string;
  cancelReason: string;
  note: string;
};

const initialFormState: FormState = {
  time: "",
  street: "",
  house: "",
  apartment: "",
  adjustment: false,
  manager: "",
  type: "",
  source: "",
  result: "",
  cancelReason: "",
  note: "",
};

const sources = ref<SelectProps["options"]>([
  {
    value: "Другое",
    label: "Другое",
  },
  {
    value: "Сайт (Партнер)",
    label: "Сайт (Партнер)",
  },
  {
    value: "Сайт (Аренда25)",
    label: "Сайт (Аренда25)",
  },
  {
    value: "Постоянные",
    label: "Постоянные",
  },
  {
    value: "Инстаграм",
    label: "Инстаграм",
  },
  {
    value: "Facebook",
    label: "Facebook",
  },
  {
    value: "Farpost",
    label: "Farpost",
  },
]);

export const types = ref<SelectProps["options"]>([
  {
    value: "Встреча",
    label: "Встреча",
  },
  {
    value: "Встреча с ключами",
    label: "Встреча с ключами",
  },
  {
    value: "Подписание ДН",
    label: "Подписание ДН",
  },
  {
    value: "Заселение",
    label: "Заселение",
  },
  {
    value: "Встреча на КД",
    label: "Встреча на КД",
  },
  {
    value: "Забрать ключи",
    label: "Забрать ключи",
  },
  {
    value: "Приемка",
    label: "Приемка",
  },
  {
    value: "Фото",
    label: "Фото",
  },
]);

const resultsOther = ref<SelectProps["options"]>([
  {
    value: "Успех",
    label: "Успех",
  },
  {
    value: "Не успех",
    label: "Не успех",
  },
  {
    value: "Отмена",
    label: "Отмена",
  },
]);

const resultsMeet = ref<SelectProps["options"]>([
  {
    value: "Комиссия получена",
    label: "Комиссия получена",
  },
  {
    value: "Не устроил вариант",
    label: "Не устроил вариант",
  },
  {
    value: "Получен задаток",
    label: "Получен задаток",
  },
  {
    value: "Отмена",
    label: "Отмена",
  },
]);

const cancelReasons = ref<SelectProps["options"]>([
  {
    value: "Сняли не с нами",
    label: "Сняли не с нами",
  },
  {
    value: "Не подходящий вариант",
    label: "Не подходящий вариант",
  },
  {
    value: "Нет возможности показать",
    label: "Нет возможности показать",
  },
  {
    value: "Клиент не приехал",
    label: "Клиент не приехал",
  },
]);

export default defineComponent({
  name: "MeetModal",
  emits: {
    hideModal() {
      return true;
    },
  },
  props: {
    meetId: {
      type: String,
    },
    agentId: {
      type: Number,
    },
    agents: {
      type: Object,
    },
    managers: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const formRef = ref();
    const disabledEdit = ref<boolean>(!!props.meetId);

    const { mutate: createOrUpdateMeet, error: createError } = useMutation(
      CREATE_OR_UPDATE_MEET
    );

    const { result } = useQuery<{ meet: Meet }>(
      GET_MEET,
      { id: props.meetId },
      { enabled: !!props.meetId }
    );

    const formState: UnwrapRef<FormState> = reactive({
      ...initialFormState,
      hunter: props.agentId,
    });

    watchEffect(() => {
      if (result.value?.meet) {
        Object.assign(formState, {
          ...result.value.meet,
          time: moment.utc(result.value.meet?.time).local().format(),
          hunter: result.value?.meet.hunter?.id,
          manager: result.value?.meet.manager?.id,
        });
      }
    });

    const handleOk = async () => {
      await formRef.value.validate();

      const { managers, agents } = props;
      const hunter = agents?.find((agent) => agent.id == formState.hunter);
      const manager = managers?.find(
        (manager) => manager.id == formState.manager
      );

      await createOrUpdateMeet({
        ...formState,
        hunterLastName: hunter?.lastName,
        managerLastName: manager?.lastName,
      });
      emit("hideModal");
    };

    const handleCancel = () => {
      formRef.value.resetFields();
      emit("hideModal");
    };

    const enabledEdit = () => (disabledEdit.value = false);

    const rules = {};

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    return {
      formRef,
      formState,
      handleOk,
      handleCancel,
      rules,
      layout,
      sources,
      types,
      result,
      createError,
      disabledEdit,
      enabledEdit,
      capitalize,
      resultsMeet,
      resultsOther,
      cancelReasons,
      locale,
    };
  },
});
