import { createApp, h, provide } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { apolloClient } from "@/apollo/client";
import { DefaultApolloClient } from "@vue/apollo-composable";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";

createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },

  render: () => h(App),
})
  .use(store)
  .use(router)
  .use(Antd)
  .mount("#app");
