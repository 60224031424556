
import {
  UserOutlined,
  TeamOutlined,
  BarChartOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  AGENT = "AGENT",
}

export default defineComponent({
  name: "Layout",
  components: {
    UserOutlined,
    TeamOutlined,
    BarChartOutlined,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const logout = () => {
      store.commit("logout");

      localStorage.clear();

      router.push("/login");
    };

    return {
      route,
      logout,
      store,
      Role,
      collapsed: ref<boolean>(true),
      selectedKeys: ref<string[]>(["1"]),
    };
  },
});
