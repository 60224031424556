import { User } from "../../graphql/generated";

export const mutations = {
  setUser(state, user: User) {
    state.user = user;
  },
  logout(state) {
    state.user = {
      id: "",
      roles: [],
    };
  },
};
