
import { computed, defineComponent, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { Employee } from "../../graphql/generated";
import MeetModal from "@/components/MeetModal.vue";
import { capitalize, groupBy } from "lodash";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";
import { useStore } from "vuex";
import { PlusOutlined } from "@ant-design/icons-vue";
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";
import { GET_STAFF } from "@/apollo/gql.staff";

type RangeValue = [Dayjs, Dayjs];

const columns = [
  { title: "Время", dataIndex: "time", key: "time", width: 60 },
  { title: "Адрес", dataIndex: "address", key: "address" },
  { title: "Тип", dataIndex: "type", key: "type" },
  { title: "Менеджер", dataIndex: "manager", key: "manager" },
  { title: "", dataIndex: "note", key: "note", width: 25, align: "left" },
];

export default defineComponent({
  name: "HomeView",
  components: {
    MeetModal,
    PlusOutlined,
  },
  setup() {
    const { state } = useStore();

    const modalVisible = ref<boolean>(false);
    const dateRange = ref<[string, string]>();
    const meetId = ref<string>();
    const agentId = ref<string>();

    const {
      result: meetsQuery,
      refetch,
      loading,
      variables,
    } = useQuery(
      gql`
        query getMeets($startDate: String, $endDate: String) {
          meets(startDate: $startDate, endDate: $endDate) {
            id
            time
            street
            house
            apartment
            adjustment
            type
            note
            result
            hunter {
              id
            }
            manager {
              lastName
            }
          }
        }
      `,
      {
        startDate: "",
        endDate: "",
      },
      {
        notifyOnNetworkStatusChange: true,
        pollInterval: 3000,
      }
    );

    const { result: staffResult } = useQuery(GET_STAFF);

    const showMeetModal = (agent) => {
      agentId.value = agent.id;
      modalVisible.value = true;
    };

    const hideModal = async () => {
      refetch();
      modalVisible.value = false;
      meetId.value = "";
      agentId.value = "";
    };

    const handleClickRow = (record) => {
      if (!state.user?.hunter) {
        return {
          onClick: () => {
            meetId.value = record?.id;
            modalVisible.value = true;
          },
        };
      }
    };

    const findMeets = async () => {
      variables.value = {
        startDate: dateRange.value?.[0] || "",
        endDate: dateRange.value?.[1] || "",
      };
      await refetch();
    };

    const meets = computed(() => {
      const isBefore = (meet) =>
        moment(meet.time).isBefore(moment().endOf("day"));

      const localMeets = meetsQuery.value?.meets.map((meet) => ({
        ...meet,
      }));

      const meetsToday =
        localMeets?.filter((meet) => {
          return meet.result !== "Отмена" && isBefore(meet);
        }) || [];

      const meetsFuture = localMeets?.filter((meet) => !isBefore(meet)) || [];
      const canceled =
        localMeets?.filter(
          (meet) => meet.result === "Отмена" && isBefore(meet)
        ) || [];

      const hunterKey = "hunter.id";

      return {
        meetsToday: groupBy([...meetsToday, ...canceled], hunterKey),
        meetsFuture: groupBy(meetsFuture, hunterKey),
      };
    });

    const agents = computed<Employee[]>(
      () => staffResult.value?.staff?.agents || []
    );

    const managers = computed<Employee[]>(
      () => staffResult.value?.staff?.managers || []
    );

    return {
      meets,
      agents,
      managers,
      columns,
      modalVisible,
      showMeetModal,
      hideModal,
      loading,
      dateRange,
      findMeets,
      moment,
      handleClickRow,
      meetId,
      agentId,
      capitalize,
      dayjs,
      emptyText: "Нет встреч",
      locale,
      ranges: {
        Сегодня: [dayjs(), dayjs().add(1, "day")] as RangeValue,
        Месяц: [dayjs(), dayjs().endOf("month")] as RangeValue,
      },
      state,
    };
  },
});
