
import { defineComponent, reactive } from "vue";
import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { useRouter } from "vue-router";
import { LoginInput, Token } from "../../graphql/generated";

interface FormState {
  username: string;
  password: string;
}

export default defineComponent({
  name: "LoginView",
  setup() {
    const router = useRouter();

    const state = reactive({
      isAuthenticated: !!localStorage.getItem("accessToken"),
    });

    const formState = reactive<FormState>({
      username: "",
      password: "",
    });

    const {
      mutate: login,
      onDone,
      onError,
      error,
    } = useMutation<{ login: Token }, LoginInput>(gql`
      mutation login($email: String!, $password: String!) {
        login(data: { email: $email, password: $password }) {
          accessToken
          refreshToken
        }
      }
    `);

    const onFinish = async (values: any) => {
      await login({
        email: formState.username,
        password: formState.password,
      });
    };

    onDone(({ data }) => {
      const login = data?.login;

      if (login) {
        const { accessToken, refreshToken } = login;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        state.isAuthenticated = true;
        router.push("/");
      }
    });

    onError(({ graphQLErrors }) => {
      console.error({ graphQLErrors });
    });

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };

    return {
      error,
      formState,
      onFinish,
      onFinishFailed,
    };
  },
});
