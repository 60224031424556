import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import HomeView from "../views/HomeView.vue";
import Login from "@/views/LoginView.vue";
import { provideApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "@/apollo/client";
import store from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      layout: "Layout",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/staff",
    name: "StaffView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/StaffView.vue"),
    meta: {
      layout: "Layout",
      requiresAuth: true,
    },
  },
  {
    path: "/analytics",
    name: "AnalyticsView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AnalyticsView.vue"),
    meta: {
      layout: "Layout",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { onResult, onError } = provideApolloClient(apolloClient)(() =>
    useQuery(
      gql`
        query me {
          me {
            id
            email
            roles
            hunter
          }
        }
      `,
      {},
      { fetchPolicy: "network-only" }
    )
  );

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    onError(() => {
      return next("/login");
    });

    onResult(({ data }) => {
      if (!data?.me) {
        next("/login");
      } else {
        store.commit("setUser", data.me);
        next();
      }
    });
  } else {
    next();
  }
});

export default router;
