import gql from "graphql-tag";

export const GET_MEET = gql`
  query getMeets($id: ID!) {
    meet(id: $id) {
      id
      time
      street
      house
      apartment
      adjustment
      source
      result
      cancelReason
      note
      type
      hunter {
        id
      }
      manager {
        id
      }
    }
  }
`;

export const CREATE_OR_UPDATE_MEET = gql`
  mutation createOrUpdateMeet(
    $id: ID
    $time: String
    $department: String
    $type: String
    $hunter: ID
    $address: String
    $street: String
    $house: String
    $apartment: String
    $manager: ID
    $result: String
    $cancelReason: String
    $note: String
    $source: String
    $hunterLastName: String
    $managerLastName: String
    $adjustment: Boolean
  ) {
    createOrUpdateMeet(
      meet: {
        id: $id
        time: $time
        department: $department
        type: $type
        hunter: $hunter
        address: $address
        street: $street
        house: $house
        apartment: $apartment
        manager: $manager
        result: $result
        cancelReason: $cancelReason
        note: $note
        source: $source
        hunterLastName: $hunterLastName
        managerLastName: $managerLastName
        adjustment: $adjustment
      }
    ) {
      id
    }
  }
`;
