import gql from "graphql-tag";

export const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      email
      name
      phoneNumber
      isActive
      roles
      hunter
    }
  }
`;

export const CREATE_OR_UPDATE_USER = gql`
  mutation createOrUpdateUser(
    $id: ID
    $email: String!
    $name: String
    $password: String!
    $phoneNumber: String
    $isActive: Boolean
    $hunter: ID
    $roles: [String!]!
  ) {
    createOrUpdateUser(
      user: {
        id: $id
        email: $email
        name: $name
        password: $password
        phoneNumber: $phoneNumber
        isActive: $isActive
        roles: $roles
        hunter: $hunter
      }
    ) {
      id
    }
  }
`;

export const GET_STAFF = gql`
  query getStaff {
    staff {
      agents {
        id
        lastName
        phoneNumber
      }
      managers {
        id
        lastName
        phoneNumber
      }
    }
  }
`;

// 8800-333-3887 whirpool
// 84232-40-09-99 (8-17 Вл) серийный номер
// 229-4691
