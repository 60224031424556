import { createLogger, createStore } from "vuex";
import { User } from "../../graphql/generated";
import { mutations } from "@/store/mutations";

export const defaultState: { user: User } = {
  user: {
    id: "",
    roles: [],
  },
};

export default createStore({
  state: defaultState,
  getters: {},
  mutations,
  actions: {},
  modules: {},
  plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
});
